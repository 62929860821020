import moment from "moment";

export function compressLargeNumber(num: any) {
  const formatted =
    num >= 1e9
      ? num / 1e9
      : num >= 1e6
      ? num / 1e6
      : num >= 1e3
      ? num / 1e3
      : num;

  switch (true) {
    case num >= 1e9:
      return formatted % 1 === 0 ? `${formatted}B` : `${formatted.toFixed(1)}B`;
    case num >= 1e6:
      return formatted % 1 === 0 ? `${formatted}M` : `${formatted.toFixed(1)}M`;
    case num >= 1e3:
      return formatted % 1 === 0 ? `${formatted}K` : `${formatted.toFixed(1)}K`;
    default:
      return num % 1 === 0 ? `${formatted}` : `${formatted.toFixed(1)}`;
  }
}

export const TAB_DATA = [
  {
    title: "Sessions",
    activeIcon: "/static/listing/sessionsA.svg",
    inactiveIcon: "/static/listing/sessions.svg",
    index: 0,
    value: "S",
  },
  {
    title: "Package",
    activeIcon: "/static/listing/packageA.svg",
    inactiveIcon: "/static/listing/package.svg",
    index: 1,
    value: "P",
  },
  {
    title: "Team",
    activeIcon: "/static/listing/teamA.svg",
    inactiveIcon: "/static/listing/team.svg",
    index: 2,
    value: "C",
  },
];

export function gettingAge(birthDate) {
  if (!birthDate) {
    return "NA";
  }
  const today = moment();
  const birth = moment(birthDate);

  return today.diff(birth, "years");
}

export function gettingGender(value) {
  switch (value?.toUpperCase()) {
    case "F":
      return "Female";
    case "M":
      return "Male";
    case "N":
      return "Not prefer to say";
    default:
      return "NA";
  }
}

export function gettingGenderIcon(value) {
  switch (value?.toUpperCase()) {
    case "F":
      return "/static/connect/female.svg";
    case "M":
      return "/static/connect/male.svg";
    case "N":
      return "/static/connect/nonBinary.svg";
    default:
      return "/static/connect/nonBinary.svg";
  }
}

export function gettingUserMemberType(trainer = false, memberType) {
  if (trainer) {
    return "Lister.Trainer";
  } else if (memberType === "Member") {
    return "Lister";
  } else {
    return "Browsers";
  }
}

export function gettingDistance(value = 0) {
  return `${compressLargeNumber(value)} km away...`;
}

export function daysToYearsMonths(days = 0) {
  const daysInYear = 365;
  const daysInMonth = 30;

  const years = Math.floor(days / daysInYear);
  const remainingDaysAfterYears = days % daysInYear;
  const months = Math.floor(remainingDaysAfterYears / daysInMonth);
  const remainingDays = remainingDaysAfterYears % daysInMonth;

  return { years, months, remainingDays };
}

export const gettingDuration = (days = 0) => {
  if (days < 1) return "Duration:: NA";

  const { years, months } = daysToYearsMonths(days);

  return `Duration: ${
    years > 0 ? `${years} Year${years > 1 ? "s" : ""}` : ""
  } ${months > 0 ? `${months} Month${months > 1 ? "s" : ""}` : ""}`;
};

export const gettingEventDate = (date, startTime, endTime) => {
  return `${moment(date).format("DD MMM, YYYY")} • ${moment(
    startTime?.substring(0, 5),
    ["HH:mm"]
  ).format("hh:mm A")} - ${moment(endTime?.substring(0, 5), ["HH:mm"]).format(
    "hh:mm A"
  )}`;
};

export const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            currentLat: position.coords.latitude,
            currentLng: position.coords.longitude,
            isLocationAccessible: true,
          });
        },
        (err) => {
          resolve({
            currentLat: null,
            currentLng: null,
            isLocationAccessible: false,
          });
        }
      );
    } else {
      resolve({
        currentLat: null,
        currentLng: null,
        isLocationAccessible: false,
      });
    }
  });
};
