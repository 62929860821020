import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./index.css";
import { APP_ENV } from "./utils/Constants";

// Initialize Sentry for error monitoring
if (process.env.REACT_APP_SENTRY_ENV) {
Sentry.init({
  dsn: process.env.REACT_APP_DSN_LINK,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_SENTRY_ENV,
  // Tracing
  tracesSampleRate: 0.1, // 1 means Capture 100% of the transactions & 0.1 is 10%
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ "https://dev.fitnessmates.com.au/", "https://staging.fitnessmates.com.au/", "https://fitnessmates.com.au/" ],
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
  Sentry.init({
  beforeSend(event) {
    if (event.transaction && event.transaction.includes('health-check')) {
      return null; // Ignore health-check transactions
    }
    return event;
  },
});
}
const loadNewRelicScript = () => {
  const script = document.createElement("script");
  script.defer = true;
  // Check the environment and set the script source accordingly
  if (process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.STAGING) {
    script.src = `${process.env.PUBLIC_URL}/newrelic-staging.js`; // Staging New Relic
  }
   if(process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.PRODUCTION) {
    script.src = `${process.env.PUBLIC_URL}/newrelic-prod.js`;    // Production New Relic
  }

  // Append the script to the body
  document.body.appendChild(script);
};

// Load the New Relic script dynamically
// loadNewRelicScript();

const loadFacebookPixelScript = () => {
  if (process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.PRODUCTION) {
    const script = document.createElement("script");
    script.defer = true;
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1086366289522165');
      fbq('track', 'PageView');
    `;
    document.body.appendChild(script);

    const noscript = document.createElement("noscript");
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=1086366289522165&ev=PageView&noscript=1"
      />
    `;
    document.body.appendChild(noscript);
  }
};

// loadFacebookPixelScript();

const loadGoogleAnalyticsScript = () => {
  if (process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.PRODUCTION) {
  // Create and inject the first script for gtag.js
  const gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-ZHN2RJVM8P";
   
  // Create and inject the second script for gtag configuration
  gtagScript.onload = () => {
  const gtagConfig = document.createElement('script');
   gtagConfig.innerHTML = `
     window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-ZHN2RJVM8P');
   `;
   document.head.appendChild(gtagConfig);
  }
  // Handle script load errors
  gtagScript.onerror = () => {
    console.error("Failed to load Google Analytics script.");
  };

  document.head.appendChild(gtagScript);
}
}

// if (process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.PRODUCTION) {
//   loadGoogleAnalyticsScript();
// }
// Add meta tags or canonical link
const updateHeadTags = () => {
if (process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.PRODUCTION) {
  const canonicalLink = document.createElement("link");
  canonicalLink.rel = "canonical";
  canonicalLink.href = "https://fitnessmates.com.au/";
  document.head.appendChild(canonicalLink);
} else {
  const metaTag = document.createElement("meta");
  metaTag.name = "robots";
  metaTag.content = "noindex, nofollow";
  document.head.appendChild(metaTag);
}
}
// Execute external script loading after app initialization
const initializeExternalScripts = async () => {
  try {
    await Promise.all([
      loadNewRelicScript(),
      loadFacebookPixelScript(),
      loadGoogleAnalyticsScript(),
    ]);
  } catch (err) {
    console.error("Error loading external scripts:", err);
  }
};

updateHeadTags();
initializeExternalScripts();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
