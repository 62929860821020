import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

interface PrimaryButtonProps extends ButtonProps {
  maxWidth?: any;
  minWidth?: any;
  loading?: boolean;
}

const StyledButton = styled(Button)(({ maxWidth }: PrimaryButtonProps) => ({
  backgroundColor: "#EE7830",
  color: "#FFFFFF",
  fontFamily: "Open sans, sans-serif",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "capitalize",
  padding: "16px 44px",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: "#EE7830cc",
  },
  "@media screen and (max-width:768px)": {
    padding: "16px",
  },
}));

const PrimaryButton = ({
  children = "Submit",
  maxWidth = false,
  minWidth = false,
  loading = false,
  onClick,
  ...restProps
}: PrimaryButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;
    if (onClick) onClick(event);
  };
  
  return (
    <StyledButton
      sx={{
        maxWidth: typeof minWidth === "string" ? minWidth : "auto",
        minWidth: typeof minWidth === "string" ? minWidth : "fit-content",
      }}
      variant="contained"
      onClick={handleClick}
      {...restProps}
    >
      {loading ? (
        <img style={{ width: "18px" }} src="/static/spin.svg" alt="loader" />
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default PrimaryButton;
