import { AUTH_SUCCESS, AUTH_LOGOUT, LOADING } from "./actionType";

interface AuthState {
  loggedIn: boolean | null;
  token: any | null;
  moreUserDetails: any | null;
  businessDetail:any | null;
  loading: boolean;
}

const initialState: AuthState = {
  loggedIn: null,
  token: false,
  moreUserDetails: null,
  businessDetail:null,
  loading: false,
};

interface Action {
  type: string;
  updates?: Partial<AuthState>;
}

// Reducer function with types
const authReducer = (
  state: AuthState = initialState,
  action: Action
): AuthState => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        ...action.updates,
      };
    case AUTH_LOGOUT:
      return {
        ...initialState,
        loggedIn: false,
      };
    case LOADING:
      return {
        ...state,
        ...action.updates,
      };
    default:
      return state;
  }
};

export default authReducer;
