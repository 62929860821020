import { AxiosResponseHeaders, AxiosRequestConfig } from "axios";

export type CookieType = {
  accessToken?: string;
};

export type CookiesKeysType =
  | "user"
  | "access_token"
  | "refresh_token"
  | "data"
  | "user_data"
  | "api_access_token"
  | "bearerAuth";

export type ApiResponseType<T = any, D = any> = {
  data: T;
  status: StatusType;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
};

export type StatusType = {
  code: number;
  message: string | any;
};

export enum BUTTON_TYPE {
  TYPE_SUBMIT = "submit",
  TYPE_BUTTON = "button",
  TYPE_RESET = "reset",
}

export enum PROVIDER_TYPE {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
}

export enum USER_TYPE {
  MEMBER = "Member",
  GUEST = "Guest",
  TRAINER = "Trainer",
  BUSSINES = "Business",
  INDIVIDUAL = "Individual"
}

//Responses Type

export interface ResponseType<T> {
  status?: any;
  data: T;
}

export type LoginResType = {
  Token: {
    AUTH_HEADER_TYPES: Array<string>;
    refresh: string;
    access: string;
  };
};

export type EmpDetailResType = {
  boide_id: string | any;
  branch: string;
  company: string;
  email: string;
  face_image: null;
  first_name: string;
  id: string;
  last_name: string;
  middle_name: string;
  phone_number: any;
  unique_id?: any;
};

export type SuccessResType = {
  Success?: any;
  message?: string | any;
};

export type UserParams = {
  search?: string | any;
};

export type DateTypes =
  | "MM/DD/YYYY"
  | "YYYY-MM-DD HH:mm"
  | "YYYY-MM-DD"
  | "DD / MM / YYYY"
  | "YYYY-MM-DD HH:mm:ss"
  | "dddd"
  | "DD MMMM"
  | "YYYY"
  | "Do MMM"
  | "ddd, M MMMM"
  | "ddd"
  | "ddd, D MMMM"
  | "Do MMMM YYYY"
  | "Do MMMM";

export type TimeTypes = "HH:mm" | "hh:mm A" | "h:mm" | "HH:mm:ss";

export type GenerateFormKeysType =
  | "start_date"
  | "start_time"
  | "end_date"
  | "end_time";

export type GenerateFormDataType = {
  start_date: string | any;
  start_time: string | any;
  end_date: string | any;
  end_time: string | any;
};

export type AddEmpResType = {
  Success?: SuccessResType;
  data?: EmpDetailResType;
  message?: string | any;
};

export enum API_HEADERS {
  CONTENT_TYPE = "Content-Type",
  AUTHORIZATION = "Authorization",
  TYPE_FORM_DATA = "multipart/form-data",
  TYPE_RAW_DATA = "application/json",
  TYPE_MULTIPART_DATA = "multipart/form-data",
  TYPE_PLAIN_TEXT = "text/plain",
  TOKEN_TYPE = "Bearer",
}

export enum CARD_TYPE {
  M = "mastercard",
  V = "visa",
}
export enum LISTING_TYPE {
  S = "S",
  M = "P",
  C = "C",
  SPECIAL = "SPECIAL",
}

export enum DIFFICULTY {
  B = "B",
  I = "I",
  A = "A",
}
export enum GENDER_PREFERENCE {
  A = "ALL",
  M = "M",
  F = "F",
  N = "N",
}

export enum FREQUENCY_TYPE {
  D = "Day",
  M = "Month",
  W = "Week",
}

export enum SCHEDULE_TYPE {
  O = "onetime",
  R = "recurring",
}
export enum RECURRING_TYPE {
  C = "customday",
  R = "recurring",
}

export enum PACKAGE_TYPE {
  S = "S", //single
  M = "M", //multiple
}

export const Genders = {
  Male: "M",
  Female: "F",
  "Non-Binary": "N",
};

export const GendersTypeSpecific = [
  {
    value: "M",
    activeImg: "/static/mdi_gender-male.svg",
    title: "Male",
  },
  {
    value: "F",
    activeImg: "/static/mdi_gender-female-orange.svg",
    title: "Female",
  },
];

export const GendersType = [
  ...GendersTypeSpecific,
  {
    value: "N",
    activeImg: "/static/mdi_gender-non-binary.svg",
    title: "Prefer not to say",
  },
];

export enum NOTIFICATION_TYPE {
  TNC = "Terms and conditions updated",
  PTU = "Payment terms updated",
  PPU = "Privacy policy updated",
  ADMIN = "Sent by admin",
  PN = "Push Notification",
  PFS = "User Profile Section",
  TM = "Team Membership",
  CB = "Cancelled Booking",
  MCL = "My Created Listing",
  PYS = "Payment Section",
  WL = "Waitlist Listing",
  CDN = "CHAT Direct Notification",
  CGN = "CHAT Group Notification",
}

export enum PAYOUT_METHOD {
  STRIPE = "Through Stripe",
  BANK = "Direct Bank Transfer",
}

export enum SESSION_SCHEDULE {
  ONETIME = "O",
  RECURRING = "R",
  R_MONTH = "Month",
  R_WEEK = "Week",
  R_DAY = "Day",
}

export enum SESSION_END_TYPE {
  END_AFTER = "end-after",
  END_BY_DATE = "end-by",
}